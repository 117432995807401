<template>
    <div>
        <b-card no-body class="mb-0">

            <div class="m-2">

                <!--Filters-->
                <b-row class="mb-2">

                    <b-col cols="12" md="4" class="mb-2 mb-md-0">
                        <v-select
                            v-model="filters.type"
                            :options="typeFilterItems"
                            label="title"
                            :reduce="item=>item.value"
                            :clearable="true"
                            placeholder="Kullanıcı Türü"
                        />
                    </b-col>
                    <b-col cols="12" md="3" class="mb-2 mb-md-0">
                        <v-select
                            v-model="filters.status"
                            :options="statusItems"
                            label="title"
                            :reduce="item=>item.value"
                            :clearable="true"
                            placeholder="Durumu"
                        />
                    </b-col>
                    <b-col cols="12" md="4" class="mb-2 mb-md-0">
                        <b-form-input
                            v-model="filters.q"
                            class="d-inline-block mr-1"
                            placeholder="Ara..."
                        />
                    </b-col>
                    <b-col cols="12" md="1" class="d-flex justify-content-end mb-2 mb-md-0">
                        <b-button type="button" variant="outline-info" @click="drawItems">
                            <feather-icon icon="SearchIcon" />
                        </b-button>
                    </b-col>

                </b-row>

                <!--Table-->
                <b-row>
                    <b-col cols="12">
                        <b-table
                            ref="refUserListTable"
                            class="position-relative"
                            :items="fetchedUsers"
                            responsive
                            :fields="tableColumns"
                            primary-key="id"
                            :sort-by.sync="filters.orderBy"
                            show-empty
                            :empty-text="loadingString"
                            :sort-desc.sync="isSortDirDesc"
                        >

                            <template #cell(user)="data">
                                <b-media vertical-align="center">
                                    <template #aside>
                                        <b-avatar
                                            size="32"
                                            :src="$basePath+data.item.fileName"
                                            :text="avatarText(data.item.name)+avatarText(data.item.surname)"
                                            :variant="`light-primary`"
                                            :to="{ name: 'users_detail', params: { id: data.item.id } }"
                                        />
                                    </template>
                                    <b-link
                                        :to="{ name: 'users_detail', params: { id: data.item.id } }"
                                        class="font-weight-bold d-block text-nowrap"
                                    >
                                        {{ data.item.name+' '+data.item.surname }}
                                    </b-link>
                                    <div><a :href="'mailto:'+data.item.email"><small class="text-muted">{{ data.item.email }}</small></a></div>
                                    <div><a :href="'tel:'+data.item.phone"><small class="text-muted">{{ data.item.phone }}</small></a></div>
                                    <div>
                                        <span class="typeBadge" :style="{backgroundColor: getCompanyColor(data.item.type, .13), color: getCompanyColor(data.item.type, 1)}">{{ types[data.item.type] }}</span>
                                    </div>
                                </b-media>
                            </template>

                            <template #cell(company)="data">
                                <b-media vertical-align="center" v-if="data.item.companyId">
                                    <template #aside>
                                        <b-avatar
                                            size="32"
                                            :src="$basePath+data.item.logo"
                                            :text="avatarText(data.item.companyName).substring(0,2)"
                                            :variant="`light-primary`"
                                            :to="{ name: 'users_detail', params: { id: data.item.id } }"
                                        />
                                    </template>
                                    <b-link
                                        :to="{ name: 'users_detail', params: { id: data.item.id } }"
                                        class="font-weight-bold d-block text-nowrap"
                                    >
                                        {{ data.item.companyName }}
                                    </b-link>
                                    <div><a :href="'mailto:'+data.item.compEmail"><small class="text-muted">{{ data.item.compEmail }}</small></a></div>
                                    <div><a :href="'tel:'+data.item.compPhone"><small class="text-muted">{{ data.item.compPhone }}</small></a></div>
                                    <div><a :href="data.item.website"><small class="text-muted">{{ data.item.website }}</small></a></div>
                                </b-media>
                            </template>

                            <template #cell(status)="data">
                                <b-badge
                                    pill
                                    :variant="`light-${statusVariants[data.item.status]}`"
                                    class="text-capitalize"
                                >
                                    {{ statusOptions[data.item.status] }}
                                </b-badge>
                            </template>

                            <template #cell(actions)="data">
                                <b-button :to="{name:'users_detail', params:{id:data.item.id}}" variant="outline-primary">
                                    <feather-icon icon="EyeIcon" />
                                </b-button>
                            </template>

                        </b-table>
                    </b-col>
                </b-row>

                <!--Pagination && Row Count-->
                <b-row class="mt-2 flex-wrap flex-md-row-reverse">
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-center justify-content-md-end mb-2 mb-md-0">
                        <b-pagination
                            v-model="filters.page"
                            :total-rows="totalUsers"
                            :per-page="filters.perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                            :disabled="!loaderStatus.users"
                            :class="{hidden:totalUsers===999999}"
                        >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>
                    </b-col>
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-center justify-content-md-start mb-1 mb-md-0">
                        <label>Tek ekranda</label>
                        <v-select
                            v-model="filters.perPage"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label>kayıt göster</label>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {avatarText, title} from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
    name: "list",
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BPagination,

        vSelect,
    },
    data(){
        return {
            filters: {
                perPage: 10,
                page: 1,
                q: null,
                orderBy: 'id',
                orderType: 'ASC',
                type: null,
                status: null
            },
            totalUsers: 999999,
            fetchedUsers: [],
            perPageOptions: [10, 25, 50, 100],
            typeFilterItems: [{label:'Yükleniyor...'}],
            statusItems: [{title:'Aktif', value:1}, {title:'Pasif', value:0}],
            isSortDirDesc: false,
            loaderStatus: {
                types: false,
                users: true
            },
            tableColumns: [
                { key: 'id', label:'#',  sortable: true },
                { key: 'user', label:'Kullanıcı' },
                { key: 'company', label:'Şirket' },
                { key: 'status', label:'Durum' },
                { key: 'actions', label:'İşlemler'  },
            ],
            types:{
                1: 'Bireysel Kullanıcı',
                2: 'Veteriner',
                3: 'Pet Shop',
                4: 'Pet Hotel',
                5: 'Rastaurant/Cafe',
                6: 'Eğitim Merkezi',
                7: 'Otel',
                8: 'Barınak',
                9: 'Pet Kuaför',
                100: 'Admin'
            },

            statusOptions: {
                0: 'Pasif',
                1: 'Aktif'
            },
            statusVariants: {
                0: 'warning',
                1: 'success'
            }
        }
    },
    created(){
        let t = this
        t.$http.get('admin/user/getRoles').then(res=>{
            let {data} = res.data
            let items = []
            for(let i = 0; i<data.length; i++){
                items.push({title: data[i].name, value: data[i].type})
            }
            t.typeFilterItems = items
        }).catch(err=>{
            console.log(err)
        }).then(f=>{
            t.loaderStatus.types = true
        })
        if(t.$route.params.page){t.filters.page = t.$route.params.page}
        t.drawItems()
    },
    watch:{
        isSortDirDesc(){
            this.filters.orderType = this.isSortDirDesc?'DESC':'ASC'
        },
        '$route.params.page'(){
            this.filters.page = this.$route.params.page
        },
        'filters.page'(){
            this.$router.push({name:'users_paged', params: {page: this.filters.page}})
            this.drawItems()
        },
        'filters.orderBy'(){
            this.drawItems()
        },
        'filters.orderType'(){
            this.drawItems()
        },
        'filters.perPage'(){
            this.drawItems()
        }
    },
    computed:{
        loadingString(){
            return this.loaderStatus.users?'Aramanızla Eşleşen Kullanıcı Bulunamadı. Lütfen Aramanızı Daraltarak Tekrar Deneyin.':'Kullanıcılar Yükleniyor Lütfen Bekleyin.'
        }
    },
    methods:{
        drawItems(){
            let t = this
            t.fetchedUsers = []
            if(t.loaderStatus.users){
                t.loaderStatus.users = false
                store.commit('app/SET_LOADING', true)
                store
                    .dispatch('app-users/fetchUsers', this.filters)
                    .then(response => {
                        const { users, total } = response.data.data
                        t.loaderStatus.users = true

                        if(total && !users.length){
                            t.filters.page=1
                            t.totalUsers = total
                        }else{
                            store.commit('app/SET_LOADING', false)
                            t.totalUsers = total
                            t.fetchedUsers = users
                        }


                    })
                    .catch(() => {
                        t.loaderStatus.users = true
                        store.commit('app/SET_LOADING', false)
                        t.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Error fetching users list',
                                icon: 'AlertTriangleIcon',
                                variant: 'danger',
                            },
                        })
                    })
            }
        },
        getCompanyColor(type, opacity){
            let hex = this.$store.state.appConfig.compTypeColors[type]
            let c = hex.substring(1).split('');
            if(c.length== 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+opacity+')';
        },
        avatarText(value){
            return avatarText(value)
        }
    }
}
</script>

<style scoped lang="scss">

.typeBadge{
    display: inline-block;
    border-radius: 5px;
    padding: 2px 7px;
    font-size: 10px;
}
.hide{
    opacity: 0;
}
</style>
